.chainpage {
  @apply flex w-full flex-col mx-auto md:pb-10;
  &_banner {
    @apply flex w-full h-screen items-center justify-end flex-col relative;
    background-image: url(../../assets/images/stellarhome.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    &_subtext {
      @apply text-2xl;
      color: #9a9a9a;
    }
    &_text {
      @apply text-white capitalize font-semibold text-5xl mt-4;
    }
  }
  &_main {
    @apply flex flex-col mt-8 mx-auto text-white rounded-xl;
    width: 90%;

    &_overviewcontainer {
      @apply flex flex-col w-full p-6;
      border: 1px solid #141414;
      background: linear-gradient(180deg, #111 0%, #0c0c0c 100%);
      &_outer {
        @apply flex w-full rounded-xl p-1;
        border: 1px solid #1b1b1b;
        background: #060606;
      }
      &_title {
        @apply text-xl font-bold;
        background: linear-gradient(
          94deg,
          #fff 6.87%,
          rgba(255, 255, 255, 0.64) 103.8%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      &_text {
        @apply font-medium mt-4 text-lg;
        color: #606060;
      }
    }
    &_statscontainer {
      @apply flex flex-col sm:flex-row gap-6 w-full justify-between mt-8;
      &_box {
        @apply flex flex-col rounded-xl py-6 px-8 w-full sm:w-1/3 gap-4;
        border: 1.5px solid #242424;
        background: #101010;
        &_value {
          @apply text-4xl;
          background: linear-gradient(95deg, #e0f464 9.63%, #70d96e 110.02%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .two {
          background: linear-gradient(95deg, #64f4c9 9.63%, #6e8cd9 110.02%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .three {
          background: linear-gradient(95deg, #a285f3 9.63%, #fa8ace 110.02%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        &_title {
          @apply font-medium;
          color: #545454;
        }
      }
    }
    &_gallerycontainer {
      @apply p-6 flex flex-col sm:flex-row gap-4 mt-16;
      height: 500px;
      border-radius: 14px;
      border: 1px solid #2f2f2f;
      background: linear-gradient(180deg, #060606 0%, #0b0b0b 100%);
      &_col1 {
        width: 30%;
        @apply flex flex-col gap-4;
        &_row1 {
          @apply font-medium text-4xl items-center justify-center flex;
          background: linear-gradient(
            94deg,
            #fff 6.87%,
            rgba(255, 255, 255, 0.64) 103.8%
          );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          height: 30%;
        }
        &_row2 {
          @apply relative rounded-xl;
          height: 70%;
          &_bg {
            // background-image: url(../../assets/images/stellar_col1.png);
            background-size: cover;
            display: block;
            filter: blur(5px);
            -webkit-filter: blur(20px);
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            position: absolute;
            z-index: 1;
          }
          &_img {
            @apply rounded-xl;
            // background-image: url(../../assets/images/stellar_col1.png);
            background-size: cover;
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 10;
            transform: translate(-50%, -50%);
            display: block;
          }
        }
      }
      &_col2 {
        @apply relative rounded-xl;
        width: 33%;
        &_bg {
          // background-image: url(../../assets/images/stellar_col2.png);
          background-size: cover;
          display: block;
          filter: blur(5px);
          -webkit-filter: blur(20px);
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          position: absolute;
          z-index: 1;
        }
        &_img {
          @apply rounded-xl;
          // background-image: url(../../assets/images/stellar_col2.png);
          background-size: cover;
          width: 100%;
          height: 100%;
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          z-index: 10;
          transform: translate(-50%, -50%);
          display: block;
        }
      }
      &_col3 {
        width: 37%;
        @apply flex flex-col gap-4;
        &_row1 {
          @apply relative rounded-xl;
          height: 40%;
          &_bg {
            // background-image: url(../../assets/images/stellar_col3_row1.png);
            background-size: cover;
            display: block;
            filter: blur(5px);
            -webkit-filter: blur(20px);
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            position: absolute;
            z-index: 1;
          }
          &_img {
            @apply rounded-xl;
            // background-image: url(../../assets/images/stellar_col3_row1.png);
            background-size: cover;
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 10;
            transform: translate(-50%, -50%);
            display: block;
          }
        }
        &_row2 {
          @apply relative rounded-xl;
          height: 60%;
          &_bg {
            // background-image: url(../../assets/images/stellar/col3_row2.png);
            background-size: cover;
            display: block;
            filter: blur(5px);
            -webkit-filter: blur(20px);
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            position: absolute;
            z-index: 1;
          }
          &_img {
            @apply rounded-xl;
            // background-image: url(../../assets/images/stellar_col3_row2.png);
            background-size: cover;
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 10;
            transform: translate(-50%, -50%);
            display: block;
          }
        }
      }
    }
    @media (max-width: 640px) {
      &_gallerycontainer {
        @apply p-6 flex flex-col gap-4 mt-16;
        height: auto;
        border-radius: 14px;
        border: 1px solid #2f2f2f;
        background: linear-gradient(180deg, #060606 0%, #0b0b0b 100%);
        &_col1 {
          width: 100%;
          @apply flex flex-col gap-4;
          &_row1 {
            @apply font-medium text-4xl items-center justify-center flex;
            height: 200px;
            background: linear-gradient(
              94deg,
              #fff 6.87%,
              rgba(255, 255, 255, 0.64) 103.8%
            );
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          &_row2 {
            @apply relative rounded-xl;
            // height: 70%;
            height: 300px;
            &_bg {
              // background-image: url(../../assets/images/stellar_col1.png);
              background-size: cover;
              display: block;
              filter: blur(5px);
              -webkit-filter: blur(20px);
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              position: absolute;
              z-index: 1;
            }
            &_img {
              @apply rounded-xl;
              // background-image: url(../../assets/images/stellar_col1.png);
              background-size: cover;
              width: 100%;
              height: 100%;
              display: block;
              position: absolute;
              top: 50%;
              left: 50%;
              z-index: 10;
              transform: translate(-50%, -50%);
              display: block;
            }
          }
        }
        &_col2 {
          @apply relative rounded-xl hidden;
          width: 100%;
          height: 200px;
          &_bg {
          }
          &_img {
          }
        }
        &_col3 {
          width: 100%;
          @apply flex flex-row gap-4;
          height: 200px;
          &_row1 {
            @apply relative rounded-xl w-1/2;
            height: 200px;
            &_bg {
            }
            &_img {
            }
          }
          &_row2 {
            @apply relative rounded-xl w-1/2;
            height: 200px;
            &_bg {
            }
            &_img {
              @apply rounded-xl;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 1239px) {
  .chainpage {
    width: 100%;
  }
}

@media (min-width: 1240px) and (max-width: 1559px) {
  .chainpage {
    width: 1200px;
  }
}
@media (min-width: 1560px) {
  .chainpage {
    width: 1350px;
  }
}
