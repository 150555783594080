@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "Roobert";
  src: url(./assets/fonts/Roobert-Regular.ttf);
}
body {
  margin: 0;
  font-family: "Roobert", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #060606;
}

code {
  font-family: "Roobert", sans-serif;
}

.slick-slide > div {
  margin: 0 10px;
}