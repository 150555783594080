.login {
  @apply flex justify-center mx-auto h-screen overflow-hidden;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(../../assets/images/loginbg.png);
  background-size: cover;
  &_main {
    @apply flex mt-60 sm:mt-72 items-center flex-col w-10/12;
    &_login {
      @apply opacity-75 text-center font-medium text-base sm:text-2xl;
      color: #b09dfc;
    }
    &_title {
      @apply text-white opacity-75 text-center font-medium text-2xl sm:text-5xl;
    }
    &_subtitle {
      @apply opacity-75 text-center font-medium sm:mt-4 text-base sm:text-xl sm:w-5/12;
      color: #636363;
    }
    &_container {
      @apply flex gap-5 mt-12 sm:mt-24 flex-col items-center;
      &_googlebutton {
        @apply text-sm sm:text-base rounded-md flex text-white gap-2 sm:gap-4 p-2 sm:p-3 w-52 sm:w-96 justify-center items-center font-medium cursor-pointer;
        border: 1px solid var(--ddnight-content-border-border-grey-75, #2c2e31);
        background: var(--ddnight-material-100, #08090b);
        box-shadow: 0px 12px 24px 0px rgba(7, 7, 8, 0.5);
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 1239px) {
  .login {
    width: 100%;
  }
}

@media (min-width: 1240px) and (max-width: 1559px) {
  .login {
    width: 1200px;
  }
}
@media (min-width: 1560px) {
  .login {
    width: 1350px;
  }
}
