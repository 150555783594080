.contact {
  @apply flex justify-center mx-auto h-screen overflow-hidden;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(../../assets/images/circlebg.png);
  background-size: cover;
  &_main {
    @apply flex sm:mt-36 xl:mt-40 mt-24 items-center flex-col w-10/12;
    &_contact {
      @apply opacity-75 text-center font-medium sm:text-3xl text-xl;
      color: #fae2a7;
    }
    &_title {
      @apply text-white opacity-75 text-center font-medium sm:text-5xl text-3xl;
    }
    &_subtitle {
      @apply opacity-75 text-center font-medium mt-1 xl:mt-4 text-sm sm:text-xl sm:w-5/12 ;
      color: #636363;
    }
    &_container {
      @apply flex gap-5 xl:mt-12 mt-4 flex-col items-center overflow-scroll;
      &_inputcontainer {
        @apply flex flex-col gap-2;
      }
      &_title {
        @apply sm:text-lg text-sm;
        color: #bbb;
      }
      &_input {
        @apply  text-sm sm:text-base rounded-md px-4 py-3  w-[320px] md:w-[600px] sm:w-[400px] outline-none;
        color: #EFEFEF;
        border: 1px solid #2b2b2b;
        background: #111;
      }
      &_submit {
        @apply rounded-md p-2.5 w-64 text-center sm:mt-4 xl:mt-14 mt-2 cursor-pointer;
        background: #f2f2f2;
        box-shadow: 0px 2px 1px 0px #fff inset,
          0px -2px 1px 0px rgba(10, 10, 10, 0.29) inset;
      }
    }
  }
  &_success {
    @apply flex mt-24 sm:mt-44 xl:mt-72 items-center flex-col w-10/12;
    &_icon {
      @apply w-80 h-80;
      img {
        @apply h-full w-full;
      }
    }
    &_title {
      @apply text-xl sm:text-2xl text-white font-medium mt-2 sm:mt-4 xl:mt-12 text-center;
    }
    &_subtitle {
      @apply mt-1 sm:mt-2 w-10/12 xl:w-2/12 text-center text-sm sm:text-base;
      color: #9d9fa4;
    }
    &_submit {
      @apply rounded-md p-2.5 w-64 text-center mt-4 sm:mt-8 xl:mt-12 cursor-pointer;
      background: #f2f2f2;
      box-shadow: 0px 2px 1px 0px #fff inset,
        0px -2px 1px 0px rgba(10, 10, 10, 0.29) inset;
    }
  }
}
