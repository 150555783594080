.ecosystems {
  @apply flex justify-center mx-auto h-screen;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(../../assets/images/circlebg.png);
  background-size: cover;
  &_main {
    @apply flex mt-40 sm:mt-48 xl:mt-72 items-center flex-col w-10/12;
    &_title {
      @apply text-white opacity-75 text-center font-bold text-3xl sm:text-4xl;
    }
    &_subtitle {
      @apply text-white opacity-75 text-center font-medium mt-2 sm:mt-4 text-lg sm:text-2xl sm:w-5/12;
    }
    &_container{
      @apply flex gap-4 sm:gap-16 mt-8 sm:mt-12 xl:mt-16;
      &_ecosystem{
        @apply h-24 w-24 sm:h-48 sm:w-48;
        img{
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}
