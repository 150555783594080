.resourcehub {
  @apply flex flex-col mx-auto;
  overflow-x: hidden;
  &_banner {
    @apply flex justify-between items-center mx-auto mt-32 md:mt-0;
    width: 90%;
    &_left {
      &_title {
        @apply text-7xl font-bold;
        line-height: 125%;
        background: linear-gradient(97deg, #90f7c5 9.46%, #32f3e8 108.25%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      &_subtitle {
        @apply font-bold text-2xl text-right;
        color: #7d7d7d;
        text-transform: capitalize;
      }
    }
    &_right {
      @apply mt-8;
      transform: translateX(10%);
      img {
        height: 200px;

        @media (min-width: 768px) and (max-width: 1239px) {
          height: 450px;
          transform: translateX(20%);
        }

        @media (min-width: 1240px) and (max-width: 1559px) {
          height: 500px;
          transform: translateX(15%);
        }
        @media (min-width: 1560px) {
          height: 600px;
        }
      }
    }
  }
  &_main {
    @apply flex flex-col mx-auto;
    width: 90%;
    &_title {
      @apply text-xl sm:text-3xl font-medium;
      background: linear-gradient(
        94deg,
        #fff 6.87%,
        rgba(255, 255, 255, 0.64) 103.8%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    &_subtitle {
      @apply font-medium mt-2 sm:mb-8 text-base sm:text-lg;
      color: #707070;
      &_span {
        color: #f1f1f1;
        text-transform: capitalize;
      }
    }
    &_resourcecontainer {
      @apply flex flex-col mt-2 gap-4;
      justify-content: center;

      .MiniCourses{
        margin-left: 9px;
      }

      &_row {
        @apply gap-4 flex sm:flex-row;
        .item-1 {
          @apply w-full sm:w-[30%] rounded-xl text-lg sm:text-2xl lg:text-4xl text-white font-bold p-4 sm:p-6 sm:pt-[120px] h-40 md:h-[420px] xl:h-[500px] bg-auto;
          border: 1px solid #141414;
          background: #000002;
          background-position: top right;
          background-repeat: no-repeat;
          background-size: 250px 200px;
          div {
            width: 45%;
          }
        }
        .item-2 {
          @apply w-full sm:w-[70%] h-40 md:h-[420px] xl:h-[500px] rounded-lg overflow-hidden;

          iframe {
            width: 100%;
            height: 100%;
          }
        }
        .item-3 {
          @apply w-1/3 sm:w-[20%] h-20 sm:h-[200px] rounded-lg p-4 text-base sm:text-xl font-semibold text-white;
          background: linear-gradient(180deg, #8987f7 0%, #541fea 100%);
          cursor: pointer;
        }
        .item-4 {
          @apply w-1/3 sm:w-[20%] rounded-lg p-4 text-base sm:text-xl font-semibold;
          background: linear-gradient(180deg, #f9d579 0%, #f08e33 100%);
          cursor: pointer;
        }
        .item-5 {
          @apply w-1/3 sm:w-[60%] h-20 sm:h-[200px] rounded-lg p-4 font-semibold text-white text-base sm:text-4xl flex justify-center items-center;
          background: linear-gradient(180deg, #8987f7 0%, #541fea 100%);
          cursor: pointer;
        }

        .item-6 {
          @apply w-1/4 sm:w-[20%] rounded-lg p-2 sm:p-4 h-20 sm:h-[200px] text-base sm:text-xl font-semibold;

          // width: 20%;
          background: linear-gradient(180deg, #86f792 0%, #1fe94c 100%);
          cursor: pointer;
        }
        .item-7 {
          @apply w-1/4 sm:w-[40%] rounded-lg p-2 sm:p-4 h-20 sm:h-[200px] text-base sm:text-xl font-semibold text-white;

          background: linear-gradient(97deg, #2b2b2b 7.54%, #0f0f0f 101.35%);
          cursor: pointer;
        }
        .item-8 {
          @apply w-1/4 sm:w-[20%] rounded-lg p-2 sm:p-4 text-base sm:text-xl  h-20 sm:h-[200px] font-semibold;

          background: linear-gradient(180deg, #a2eff4 0%, #227ce5 100%);
          cursor: pointer;
        }
        .item-9 {
          @apply w-1/4 sm:w-[20%] rounded-lg p-2 sm:p-4 text-base sm:text-xl  text-white h-20 sm:h-[200px] font-semibold;

          background: linear-gradient(180deg, #f875a4 0%, #f41aaa 100%);
          cursor: pointer;
        }
      }
    }
    &_devcontainer {
      @apply flex flex-col mt-16;
      &_title {
        @apply text-xl sm:text-3xl font-medium;
        background: linear-gradient(
          94deg,
          #fff 6.87%,
          rgba(255, 255, 255, 0.64) 103.8%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      &_subtitle {
        @apply font-medium mt-1 sm:mb-8 text-base sm:text-lg mb-4;
        color: #707070;
      }
      &_buildcontainer {
        @apply flex px-4 sm:px-12 py-4 gap-2 sm:gap-16 bg-white rounded-xl justify-between;
        &_left {
          @apply flex flex-col justify-center w-full md:w-2/5 text-left;
          &_title {
            @apply text-xl sm:text-3xl font-semibold text-left;
          }
          &_button {
            @apply mt-3 sm:mt-8 p-3 font-medium w-fit rounded-lg cursor-pointer text-sm sm:text-base;
            background: #414141;
            color: #dedede;
          }
        }
        &_right {
          @apply hidden md:block h-96;
          img {
            @apply h-full w-full object-contain;
          }
        }
      }
      &_othercontainers {
        @apply flex flex-col sm:flex-row mt-4 sm:mt-8 gap-4 sm:gap-8;

        &_container {
          cursor: pointer;
          @apply h-20 sm:h-40 lg:h-72 flex sm:flex-col w-full sm:w-1/3 bg-white rounded-xl justify-between sm:items-start p-8 items-center;
          img {
            height: 32px;
            @apply sm:h-10 lg:h-16;
            width: auto;
            object-fit: contain;
            @apply mr-4;
          }

          &_title {
            @apply text-lg sm:text-xl lg:text-3xl font-medium flex-shrink-0;
            color: #121212;
          }
        }
      }
    }
    &_deepercontainer {
      @apply flex flex-col mt-8 sm:mt-16;
      &_title {
        @apply text-xl sm:text-3xl font-medium;
        background: linear-gradient(
          94deg,
          #fff 6.87%,
          rgba(255, 255, 255, 0.64) 103.8%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      &_subtitle {
        @apply font-medium sm:mt-2 mb-2 text-base sm:text-lg;
        color: #707070;
      }
      &_containers {
        @apply flex flex-col sm:flex-row gap-4 sm:gap-8 mt-2 mb-4;
        &_container {
          @apply w-full sm:w-1/3 rounded-xl p-1 flex flex-col justify-end;
          border: 1px solid #262626;
          background: linear-gradient(
            180deg,
            #0b0b0b 0%,
            rgba(11, 11, 11, 0.19) 100%
          );
          &_inner {
            @apply flex w-full rounded-xl p-1  h-full;
            border: 1px solid #1b1b1b;
            background: #060606;
            &_inner {
              @apply flex flex-row justify-between items-center md:flex-col rounded-xl py-2 px-4 font-medium h-full;
              width: 100%;
              border: 1px solid #141414;
              background: linear-gradient(89deg, #181818 8.64%, #111 89.93%);
              position: relative;

              img {
                @apply hidden sm:block;
                width: max-content;
                @apply sm:absolute;
                top: 0;
                right: 0;
              }
              &_title {
                @apply text-lg sm:text-2xl font-medium lg:mt-2 text-center;
                color: #f1f1f1;
              }
              &_button {
                @apply cursor-pointer p-2.5 mt-2 sm:mt-4 mb-2 sm:mb-4 rounded-md w-fit;
                background: #414141;
                color: #dedede;
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 1240px) and (max-width: 1559px) {
  .resourcehub {
    width: 1200px;
  }
}
@media (min-width: 1560px) {
  .resourcehub {
    width: 1350px;
  }
}
