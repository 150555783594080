.header {
  @apply sm:mt-4 mt-3 absolute mx-auto left-0 right-0 flex justify-center items-center sm:px-2 px-1 sm:py-2 py-1;
  width: 96%;
  border-radius: 14px;
  border: 1px solid #262626;
  background: #0b0b0b;
  max-width: 1350px;

  &_inner {
    @apply rounded-xl sm:py-6 py-4 px-6 flex justify-between items-center;
    width: 100%;
    border: 1px solid #141414;
    background: #171717;
    &_left{
        img{
            @apply sm:h-8 h-6;
        }
    }
    &_right {
      @apply flex gap-6 items-center;
      &_text {
        @apply font-medium;
        color: #5b5b5b;
      }
      &_button {
        @apply rounded-md px-3 py-2 cursor-pointer;
        background: #F2F2F2;
box-shadow: 0px 2px 1px 0px #FFF inset, 0px -2px 1px 0px rgba(10, 10, 10, 0.29) inset;
      }
    }
  }
}

// the header_inner_right class should have 0 padding in mobile screen
@media (max-width: 768px) {
  .header_inner {
    @apply px-4;
  }
}

